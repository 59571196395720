.section-blg {
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 120px;
    
}
.outer-wrap-blog {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    /* max-width: 400px; */
    /* width: auto; */
}
.card-blog {
    width: 350px;
    border-radius: 10px;
    max-height: 370px;
    /* display: flex; */
}
.cont-blog {
    /* background-color: #0e5579; */
    /* color: white; */
    padding: 10px;
    border-radius: 0 0 10px 10px;
    height: 200px;
    display: flex;
    flex-direction: column;
}
.sub-blog {
    /* width: 300px;  */
    height: 70px;
    text-overflow: ellipsis; 
    white-space: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color:#777
}
.card-blog > img {
        border-radius: 10px 10px 0 0;
        /* max-width: 100%;
        max-height: 100%; */
        height: 150px;
        width: 350px;
        object-fit: cover;
}
.cont-blog > p{
    height: 40px;
}
.sub-blog > h2{
    font-size: 15px;
    /* width: 300px;  */
    /* height: 80px; */
    line-height: 1.5em;
    /* text-align: justify; */
}
.read-more {
    color: #0e5579;
    margin-bottom: 10px;
    margin-left: 10px;
    text-decoration: none;
}

@media only screen and (max-width: 1198px) {
    .outer-wrap-blog {
        grid-template-columns: repeat(2, 1fr);
        /* max-width: 400px; */
        /* width: auto; */
    }
  }
  @media only screen and (max-width: 810px) {
    .outer-wrap-blog {
        grid-template-columns: repeat(1, 1fr);
        /* max-width: 400px; */
        /* width: auto; */
    }
  }
  @media only screen and (max-width: 404px) {
    .outer-wrap-blog {
        display: flex;
        flex-direction: column;
        /* max-width: 400px; */
        /* width: auto; */
    }
  }