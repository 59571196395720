.wrap-heads {
  /* background-color: aquamarine; */
  /* background-image: url(../../../Images/ooh-bg.png); */
  background-size: cover;
  padding: 200px 50px 200px 50px;
  /* margin-bottom: 150px; */
  background-position: 0% 20%;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.4);
}

.cont-heads {
  text-align: left;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  flex-direction: column;
  color: white;
}
.cont-heads > h1 {
  text-align: left !important;
}
.cont-heads > h3 {
  font-size: 15px;
  line-height: 25px;
}
.cont-heads > img {
  opacity: 50%;
}
.services-btn {
  margin-top: 10px;
  background-color: #ffffff;
  /* box-shadow: none !important; */
  border: none;
  font-weight: bold;
  font-size: 15px;
  color: #0e5579;
  width: 150px;
}
.services-btn:hover {
  background-color: #0e5579 !important;
  color: white !important;
}
