.wrap-abt2 > h1 {
    /* text-align: center; */
    background: linear-gradient(90deg, #02193A 0%, #0E5579 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: bold;
}
.wrap-abt2 > h3 {
    margin-top: 70px;
    font-weight: bold;
    font-size: 20px;
}
.cont-abt3 > h3{
    font-size: 15px;
    font-weight: normal;
    text-align: justify;
    line-height: 25px;
}
.cont-abt3 > h1{
    font-size: 24px;
    font-weight: bold;
    /* position: relative; */
    /* display: flex; */
}
.wrap-line {
    display: flex;
    position: relative;
}
.wrap-line > h1{
    font-size: 24px;
    font-weight: bold;
    /* position: relative; */
    /* display: flex; */
}
.line1 {
    margin-top: 10px;
    /* flex-grow: 1; */
    border-color: black;
    width: 150px;
}
.line2 {
    margin-top: 10px;
    border-color: black;
    width: 180px;
}
.line3 {
    margin-top: 10px;
    border-color: black;
    width: 100px;
}
.wrap-cont-abt-te {
    /* display: flex; */
    align-items: center;
    /* gap: 10px; */
}
.cont-abt3 {
    width: auto;
}
@media only screen and (max-width: 767px) {
    .line1 {
        /* width: auto; */
        flex-grow: 1;
        margin-right: 12px;
    }
    .line2 {
        flex-grow: 1;
        margin-right: 12px;
    }
    .line3 {
        flex-grow: 1;
        margin-right: 12px;
    }
    .cont-abt3 > h3 {
        margin-top: 10px;
    }
  
  }
  @media only screen and (max-width: 389px) {
    .line1 {
        /* width: auto; */
        flex-grow: 1;
        width: auto;
    }
    .line2 {
        flex-grow: 1;
        width: auto;
    }
    .line3 {
        flex-grow: 1;
        width: auto;
    }
  }