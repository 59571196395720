.wrap-abt5-sec {
  padding-bottom: 150px;
}
.wrap-abt-slide {
  padding-bottom: 120px;
}
.wrap-abt-slide > h1 {
  font-weight: bold;
  margin-bottom: 60px;
  /* font-size: 20px; */
}
.wrap-img {
  border-radius: 15px;
  height: 360px;
  max-height: 490px;
}
.wrap-img > img {
  border-radius: 15px;
  width: 100% !important;
  /* width: auto !important; */
  margin-left: auto;
  margin-right: auto;
  cursor: grab;
}
.wrap-img > h1 {
  margin-top: 20px;
  font-weight: bold;
}
.slidur {
  display: none;
}
.carousel-abt-slide {
  padding-bottom: 30px !important;
}
.carousel-abt-slide > ul {
  bottom: 50px !important;
}
.cont-card-5abt {
  height: 440px !important;
  max-width: 420px !important;
  width: auto !important;
}
.img-card-slide-sec5abt {
  height: 350px;
  max-width: 435px;
  width: auto;
  object-fit: cover;
}
.stor {
  object-position: 0px;
}
.wrap-card-ofc {
  border-radius: 15px;
}

@media only screen and (max-width: 1399px) {
  .cont-card-5abt {
    height: 390px !important;
    max-width: 350px !important;
  }
  .img-card-slide-sec5abt {
    height: 300px;
    max-width: 330px;
  }
}
@media only screen and (max-width: 1199px) {
  .cont-card-5abt {
    height: 330px !important;
    max-width: 300px !important;
    min-width: 200px !important;
  }
  .img-card-slide-sec5abt {
    height: 250px;
    max-width: 300px;
  }
  .slick-list {
    height: 340px;
  }
  .carousel-abt-slide > ul {
    bottom: 20px !important;
  }
}
/* @media only screen and (max-width: 991px) {
  .cont-card-5abt {
    height: 350px !important;
    max-width: 225px !important;
  }
  .img-card-slide {
    height: 185px;
    max-width: 225px;
  }
  .slick-list {
    height: 350px;
  }
} */
@media only screen and (max-width: 991px) {
  .cont-card-5abt {
    height: 255px !important;
    max-width: 220px !important;
  }
  .img-card-slide-sec5abt {
    height: 175px;
    max-width: 316px;
  }
  .slick-list {
    height: 255px;
  }
  .carousel-abt-slide > ul {
    bottom: 0px !important;
  }
}
@media only screen and (max-width: 413px) {
  .carousel-abt-slide > ul {
    bottom: -20px !important;
  }
}
