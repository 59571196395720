.wrap-footer {
  background-color: #0e5579;
  display: block !important;
  /* position: fixed;
  bottom: 0;
  width: 100%; */
  /* margin-top: 70px; */
}
.cont-logo {
  display: flex;
  gap: 10px;
  align-items: center !important;
  margin-top: 10px;
  text-align: center;
}
.cont-logo > img {
  width: 21px;
  /* height: 22px; */
}
.getin {
  margin-left: -30px
}
.cont-logo > a {
  /* align-items: center; */
  margin-top: auto;
  margin-bottom: auto;
  align-self: center !important;
  text-align: center;
  font-weight: normal;
  font-size: 10px;
  color: white !important;
  text-decoration: none;
  /* line-height: 10px; */
}
.cont-footer > img {
  width: 300px;
  /* margin-top: 10px; */
  /* margin-bottom: auto; */
  margin-bottom: 30px;
}
.img-foot > img{
  width: 10px;
  margin-right: 5px;
}
/* .qt {
    margin-left: 20%;
} */
.cont-footer > h1 {
  font-weight: bold;
  color: white;
  font-size: 15px;
}

.cont-footer > h3,
.footer-nav > h3 {
  font-weight: normal;
  color: white;
  font-size: 10px;
  line-height: 15px;
}

.footer-nav {
  text-decoration: none;
}
.wa {
  padding-right: 0px;
}
@media only screen and (max-width: 992px) {
  .getin {
    margin-left: 0px
  }
}
@media only screen and (max-width: 330px) {
  .cont-footer > img {
    width: 90%;
  }
}