.gap-3 {
  gap: 45px !important;
}

.btn-submit {
  border: none;
}
.wrapper-cta {
  margin-top: 150px;
}

#section6 {
  scroll-margin-top: 150px;
}
.anim-text {
  animation: floattext 3s ease-in-out infinite;
}
.disc {
  font-size: 10px;
}
@media only screen and (max-width: 377px) {
  .wrapper-cta {
    margin-top: 100px;
  }
  #section6 {
    scroll-margin-top: 100px;
  }
}