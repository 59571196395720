* {
  font-family: "Poppins", sans-serif;
}
.sec-1-3 {
  margin-top: 0px;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400 !important;
}
.mb-60 {
  margin-bottom: 60px;
}
.fs-24 {
  font-size: 24px;
}

.fs-title {
  font-size: 40px;
}

.fs-sm-title {
  font-size: 28px;
}

.fs-big {
  font-size: 50px;
}

.fs-bigger {
  font-size: 64px;
}

.fs-20 {
  font-size: 20px;
}

.fs-16 {
  font-size: 16px;
}

.fs-15 {
  font-size: 15px;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-normal {
  font-size: 20px;
  font-weight: 400;
  color: #545659;
}

.lh-30 {
  line-height: 30px;
}
.lh-28 {
  line-height: 28px;
}

.gap-72 {
  gap: 72px;
}
.mb-150 {
  margin-bottom: 150px;
}
.mb-200 {
  margin-bottom: 200px;
}
.mt-200 {
  margin-top: 200px !important;
}
.mt-60 {
  margin-top: 60px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-120 {
  margin-top: 120px;
}
.mb-120 {
  margin-bottom: 120px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-150 {
  margin-top: 150px;
}
.mb-100 {
  margin-bottom: 100px;
}

.mb-80 {
  margin-bottom: 80px;
}
.bg-blg {
  background-image: url(./Images/Detailedserv.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.bg-blur {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0.234) 100%
  );
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */
}

.text-color-rb {
  background: linear-gradient(267.13deg, #0e5579 7.72%, #ac2523 96.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.slick-prev:before,
.slick-next:before {
  color: #0e5579 !important;
}

.jse {
  justify-content: end;
}

.color-navy {
  color: #0e5579 !important;
}

.color-gray {
  color: #545659;
}

.bg-navy {
  background: #0e5579 !important;
  color: white;
}

.ls-1 {
  letter-spacing: 1px;
}

.w-30 {
  width: 30%;
}

.py-150 {
  padding-top: 150px;
  padding-bottom: 150px;
}
.sec-1-3 {
  background-image: url(./Images/ng-land.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 150px;
  padding-bottom: 150px;
}
.wrap-sec1-ab {
  background-image: url(./Images/bg-abt1.png);
  padding-bottom: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.wrap-sec2-ab {
  background-image: url(./Images/bg-abt2.png);
  /* padding-bottom: 150px; */
  /* padding-left: -1000px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* object-fit:fill ; */
  padding-top: 150px;
}
.wrap-sec3-ab {
  background-image: url(./Images/bg-abt3.png);
  padding-bottom: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* margin-bottom: -350px !important; */
  padding-top: 120px;
}
.wrap-sec13-serv {
  background-image: url(./Images/bg-serv.png);
  padding-bottom: 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 80px;
}
.slick-dots li.slick-active button:before{
  opacity: 100% !important;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 20px !important;
    line-height: 30px !important;
    color:#0e5579 !important;
    padding-top: 10px !important;
}
.slick-prev:before {
  font-size: 25px !important;
}
.slick-next:before {
  font-size: 25px !important;
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  25% {
    transform: translatex(20px);
  }
  50% {
    transform: translatey(-20px);
  }
  75% {
    transform: translatey(20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes floattext {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
.bg-detailedBlog {
  background-image: url(./Images/bg-serv.png);
  padding-bottom: 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.scrol-blog {
  /* width: 1500px; */
  display: block;
  height: 100vh;
  /* height : calc(100% - 94px) !important; */
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 4%;
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  /* position:absolute; */
  /* margin-bottom: 60px; */
}
.scrol-blog::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
@media only screen and (max-width: 768px) {
  .slick-prev:before {
    margin-right: -50px !important;
    display: none;
  }
  .slick-next:before {
    margin-left: -40px !important;
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .sec-1-3 {
    background-image: url(./Images/ng-land-ipad.png);
  }
}
@media only screen and (max-width: 397px) {
  .wrap-sec13-serv {
  }
}
@media only screen and (max-width: 377px) {
  .sec-1-3 {
    padding-top: 50px;
    padding-bottom: 80px;
  }
  .wrap-sec1-ab {
    padding-bottom: 100px;
  }
  .wrap-sec2-ab {
    padding-top: 100px;
  }
  .wrap-sec3-ab {
    padding-top: 70px;
  }
  .wrap-sec13-serv {
    padding-bottom: 70px;
    padding-top: 70px;
  }
}
