.navBlog {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
    margin-top: 75px;
}
.shareBlog {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
@media only screen and (max-width: 575px) {
    .navBlog {
        /* flex-direction: row; */
        padding: 10px;
    }
    .shareBlog {
        flex-direction: row;
    }
  }