.cont-services {
  display: flex;
  align-items: center;
}
.cont-services > h3 {
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
  text-align: justify;
}
.cont-services > img {
  width: 400px;
  object-fit: cover;
  border-radius: 15px;

}

.wrap-services {
  gap: 0px;
  border-radius: 15px;
}
.wrap-services > div {
  border-radius: 15px;
}
@media only screen and (max-width: 991px) {
  /* .wrap-services {
    padding-bottom: 40px;
  } */
}
@media only screen and (max-width: 397px) {
  .cont-services > h3 {
    text-align: left;
  }
  .wrap-services {
    gap: 30px;
  }
}