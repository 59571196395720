.wrap-vid {
  position: relative;
    overflow: hidden !important;
    /* background-color: #0E5579; */
}
.container-secvid {
    /* margin-top: 150px; */
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 150px;
    
    
    /* transform: translateZ(0px); */
}
.container-secvid > h1 {
    /* display: flex;
      flex-direction: row; */
    /* align-items: center; */
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    padding-bottom: 60px;
    /* color: white; */
    /* color: #0E5579; */
  }
  .container-secvid > iframe {
    display:block;
    border-radius: 15px !important;
    z-index: 999 !important;
    position: relative;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  
  /* .wrap-vid > img {
    position: absolute;
    right: -250px;
    bottom: 100px;
    top: 300px;
    animation: rotation 8s infinite linear;
    z-index: 0;
    
  } */