.wrapper-timeline {
  position: relative;
}
.garis {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  /* height: 100%; */
}
.wrap-img-tl {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}
.wrap-img-tl > img {
  height: 40px;
}
.row-tl {
  margin-top: -80px !important;
}
.garisnya {
  height: 880px; 
  
  width: 8px;
}
.disp-none {
  display: block;
}
.wrap-boxd {
  opacity: 100% !important;
}
.wrap-boxd > h4{
  text-align: justify;
}
.border-left {
  border-left: 5px solid #ac2523;
}

.border-right {
  border-right: 5px solid #ac2523;
}
@media screen 
  and (min-width: 1200px) 
  and (max-width: 1399px){
    .garisnya {
      height: 879px;
    }
}
@media screen 
  and (min-width: 992px) 
  and (max-width: 1199px){
    .garisnya {
      height: 1020px;
    }
}
@media screen 
  and (min-width: 769px) 
  and (max-width: 991px){
    .garisnya {
      height: 1330px;
    }
}
@media only screen and (max-width: 768px) {
  /* For desktop: */
  .disp-none {
    display: none;
  }
  .wrap-timeline {
    margin-bottom: 40px;
  }
  .garisnya {
    height: 800px;
    /* width: 8px; */
  }
  .row-tl {
    margin-top: 0px !important;
  }
}
@media only screen and (max-width: 572px) {
  /* For desktop: */
  .disp-none {
    display: none;
  }
  .wrap-timeline {
    padding: 15px;
  }
  .garis {
    top: 20px;
  }

}
@media screen 
  and (min-width: 454px) 
  and (max-width: 574px){
    .garisnya {
      height: 905px !important;
      /* width: 8px; */
    }
}
@media only screen and (max-width: 454px) {
  /* For desktop: */
  .garisnya {
    height: 905px;
    /* width: 8px; */
  }
  .quote {
    padding-left: 20px;
    padding-right: 20px;
  }
  .wrap-boxd > img {
    max-width: 100%;
    /* border-radius: 15px; */
  }

}
@media only screen and (max-width: 390px) {
  /* For desktop: */
  .garisnya {
    height: 1005px;
    /* width: 8px; */
  }

}
@media only screen and (max-width: 352px) {
  /* For desktop: */
  .garisnya {
    height: 1105px;
    /* width: 8px; */
  }

}