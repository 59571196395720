.section1-blg {
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    /* align-items: center; */
    
}
.section1-blg > img {
    margin-top: 30px;
    max-width: 1100px;
}
@media only screen and (max-width: 575px) {
    .section1-blg {
        margin-top: 70px;
    }
  }