.sec5 {
  margin-top: 150px;
}
.sec1-wrap {
  margin-bottom: -150px !important;
}
.carousel {
  gap: 25px;
  margin-top: 60px;
  cursor: grab;
  overflow: scroll;
  padding-bottom: 30px;
  /* justify-content: center; */
}

.img-card-team {
  height: 200px;
  width: 200px;
  object-fit: cover;
  /* object-position:0mm 0.01mm */
}
.slick-team {
  margin-top: 60px;
}
.card {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0.6) 0.01%,
    rgba(255, 255, 255, 0.234) 100%
  );
  /* height: 340px !important; */
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.cont-card-team {
  height: 340px !important;
  max-width: 455px !important;
  width: auto !important;
}

.img-card-team {
  height: 350px;
  /* height: auto; */
  max-width: 435px;
  width: auto;
  object-fit: cover;
  /* image-resolution: from-image 10dpi; */
  /* object-position: 0px; */
}

.card-text {
  font-size: 16px;
  color: #545659;
}

@media only screen and (max-width: 1399px) {
  .cont-card-team {
    height: 340px !important;
    max-width: 340px !important;
    width: auto !important;
  }
}

@media only screen and (max-width: 1199px) {
  .cont-card-team {
    height: 360px !important;
    max-width: 400px !important;
    width: auto !important;
  }

  .card-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .carousel {
    gap: 30px;
  }
}
@media only screen and (max-width: 1396px) {
  .carousel {
    justify-content: left;
  }
}
