.wrap-serv5-sec {
  margin-top: 100px;
}
.wrap-serv5-sec > h1 {
  font-weight: bold;
}
.wrap-serv5-sec > h3 {
  margin-bottom: 60px;
}
.wrap-imga {
  border-radius: 15px;
}
.wrap-imga > img {
  border-radius: 15px;
  width: 50% !important;
  /* width: auto !important; */
  margin-left: auto;
  margin-right: auto;
  cursor: grab;
}
.wrap-imga > h1 {
  margin-top: 20px;
  font-weight: bold;
}

.slick-slide {
  /* margin-left: 27px;
  margin-right: 27px; */
}

.gap-30 {
  gap: 30px;
}

.carousel-abt-slide {
  padding-bottom: 30px !important;
}

.cont-card-abt {
  height: 368px !important;
  max-width: 420px !important;
  width: auto !important;
}

.img-card-slide {
  height: 350px;
  max-width: 435px;
  width: auto;
  object-fit: cover;
}

@media only screen and (max-width: 1399px) {
  .cont-card-abt {
    height: 310px !important;
    max-width: 344px !important;
    width: auto !important;
  }
  .img-card-slide {
    height: 350px;
    max-width: 380px;
    width: auto;
  }
}

@media only screen and (max-width: 1199px) {
  .cont-card-abt {
    height: 290px !important;
    max-width: 300px !important;
  }
  .img-card-slide {
    height: 300px;
    max-width: 330px;
  }
}

@media only screen and (max-width: 991px) {
  .cont-card-abt {
    height: 210px !important;
    max-width: 220px !important;
  }
  .img-card-slide {
    height: 250px;
    max-width: 240px;
  }
}
@media only screen and (max-width: 767px) {
  .cont-card-abt {
    height: 140px !important;
    max-width: 165px !important;
  }
  .img-card-slide {
    height: 180px;
    max-width: 180px;
  }
}
@media only screen and (max-width: 475px) {
  .cont-card-abt {
    height: 120px !important;
    max-width: 140px !important;
  }
  .img-card-slide {
    height: 160px;
    max-width: 160px;
  }
}
@media only screen and (max-width: 397px) {
  .cont-card-abt {
    height: 120px !important;
    max-width: 120px !important;
  }
  .img-card-slide {
    height: 90px;
    max-width: 140px;
  }
  .font-fac {
    font-size: 11px !important;
    margin-left: -10px !important;
  }
}
/* @media only screen and (max-width: 325px) {
  .cont-card-abt {
    height: 210px !important;
    max-width: 130px !important;
  }
  .img-card-slide {
    height: 110px;
    max-width: 110px;
  }
  .font-fac {
    font-size: 10px !important;
    margin-left: -20px !important;
  }
} */
