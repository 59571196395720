.cont-sec1-right > h3 {
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    /* align-items: justify; */
    text-align: justify;
}
.contsms-left {
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    /* justify-content: cen; */
}
.a-sms {
    text-decoration: none;
}
.container-sms {
    margin-bottom: 150px;
    /* text-decoration: none; */
    border-radius: 0 0 15px 15px;
    cursor: pointer;
}

.cont-sms {
    gap: 100px;
}
.container-sec1 {
    gap: 10px;
    /* margin-bottom: 150px; */
    border-radius: 15px 15px 0 0;
}
.cont-sec1-left {
    display: flex;
    align-items: center;
    
}
.cont-sec1-left > h1 {
    background-color: blue;
    /* opacity: 0.5; */
    /* border-radius: 20px; */
    font-weight: bold;
    font-size: 50px;
    line-height: 56px;
    background: -webkit-linear-gradient(#021931,#0E5579);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* align-items: justify; */
    /* text-align: justify;
     */
}
.contsms-right {
    background-color: rgb(255, 255, 255);
    /* opacity: 0.5; */
    /* border-radius: 20px; */
    font-weight: bold;
    font-size: 50px;
    line-height: 56px;
    background: -webkit-linear-gradient(#ffffff,#ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* align-items: justify; */
    /* text-align: justify;
     */
}
.cta-btn {
    background-color: #0e5579;
    border: none;
    font-weight: bold;
    font-size: 15px;
  }
  .cta-btn:hover {
    background-color: #0e5579 !important;
  }
@media only screen and (max-width: 572px) {
    /* For desktop: */
    .container-sec1 {
        margin-left: 20px;
        margin-right: 20px;
    }
    .contsms-left {
        height: 100px;
    }
    .contsms-right {
        font-size: xx-large;
    }
  
  }
  @media only screen and (max-width: 438px) {
    /* For desktop: */
    .cont-sec1-left > h1 {
        font-size: 35px;
        line-height: 40px;
    }
    .cont-sec1-right > h3 {
        /* font-weight: 400; */
        font-size: 13px;
        /* line-height: 28px; */
        /* align-items: justify; */
        text-align:left;
    }

  
  }