.nav-logo {
  height: 29px;
}
/* .Navbar {
    padding: 80px !important;
} */
.nav-btn {
  gap: 40px;
  margin-left: 10%;
}
.nav-link {
  font-size: 15px;
}
.nav-link-indo {
  font-size: 13px !important;
}
.nav-link:hover,
.nav-link:focus {
  color: #ac2532 !important;
  font-weight: 700;
}

.contact-btn {
  background-color: #0e5579;
  /* box-shadow: none !important; */
  border: none;
  font-weight: bold;
  font-size: 15px;
}
.contact-btn:hover {
  background-color: #0e5579 !important;
}
.navbar-light {
  color: #ac2532 !important;
  border-color: #ac2532 !important ;
  /* background-color: #AC2532 !important; */
}
.navbar-toggler {
  border: 0 !important;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
}
.switch.btn.btn-light{
  /* padding: 10px; */
  border-radius : 10px;
}
.switch-off {
  color:#6d6d6f;
  font-weight: 400;
}
.btn:first-child:hover, :not(.btn-check) + .btn:hover {
  color: white;
  background-color: #0e5579;
  border-color: #0e5579;
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0e5579;
  --bs-btn-border-color: #0e5579;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0e5579;
  --bs-btn-hover-border-color: #0e5579;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0e5579;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0e5579;
  --bs-btn-disabled-border-color: #0e5579;
  border-radius: 10px !important;
}
@media only screen and (max-width: 454px) {
  /* For desktop: */
  .nav-logo {
    height: 20px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .nav-btn-id {
    gap: 5px;
    /* margin-left: 10%; */
  }
}
@media only screen and (max-width: 991px) {
  /* For desktop: */
  .nav-btn {
    gap: 10px;
    margin-left: 0;
    margin-bottom: 20px;
    margin-top: 2.5%;
  }
}
